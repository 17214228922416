<template lang="html">
  <error-page-layout
    error-type="Sem permissão"
    color-type="error"
    title="Sem permissão de acesso"
    first-message="Você não tem acesso a esse recurso, entre em contato com seu gestor."
    image-name="not_authorized.png"
  >
    <v-btn slot="buttons" text color="orange" dark @click="redirect">Retornar</v-btn>
  </error-page-layout>
</template>

<script>
export default {
  components: {
    ErrorPageLayout: () => import('@/modules/errors/components/ErrorPageLayout.vue')
  },
  methods: {
    redirect() {
      this.$router.back()
    }
  }
}
</script>
